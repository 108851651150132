<style scoped lang="less">
  .top-menu {
    background-color: #FFF;
    display: flex;
    align-items: center;
    overflow: auto;
    flex-wrap: wrap;
    padding: 0 10px;
    box-sizing: border-box;
    height: 50px;
    flex-shrink: 0;
    .menu {
      font-size: 16px;
      color: #000;
      line-height: 50px;
      position: relative;
      padding: 0 5px;
      cursor: pointer;
      white-space: pre;
      word-break: keep-all;
      transition: all .3s;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all .3s;
        content: '';
        height: 3px;
        width: 100%;
        display: inline-block;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
.pink-theme {
  .top-menu {
    .menu {
      &.active {
        color: #F4628F;
        &:hover {color: #F4628F;}
      }
      &:hover { color: #F4628F; }
      &::after {
        background-color: #F4628F;
      }
    }
  }
}
.blue-theme, .dark-theme {
  .top-menu {
    .menu {
      &.active {
        color: #6B75CA;
        &:hover {color: #6B75CA;}
      }
      &:hover { color: #6B75CA; }
      &::after {
        background-color: #6B75CA;
      }
    }
  }
}

.yellow-theme {
  .top-menu {
    .menu {
      &.active {
        color: #6C573C;
        &:hover {color: #6C573C;}
      }
      &:hover { color: #6C573C; }
      &::after {
        background-color: #6C573C;
      }
    }
  }
}
</style>

<template>
  <div class="top-menu">
    <div
      class="menu"
      @click="clickMenu(menu)"
      :class="{active: menu.name === active.name}"
      v-for="(menu, i) in menus"
      :key="menu.url + i">
      {{menu.name}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      active: this.menus && this.menus.length ? this.menus[0] : null
    }
  },
  watch: {
    menus: {
      deep: true,
      handler () {
        this.active = this.menus && this.menus.length ? this.menus[0] : null
      }
    }
  },
  methods: {
    clickMenu (item) {
      this.active = item
      if (item.type === 'sys_page') {
        if (!item.url || isNaN(item.url)) {
          if (item.url) {
            this.$route.name !== item.url && this.$router.push({name: item.url})
          } else {
            this.$router.push({name: 'index'})
          }
        } else {
          this.$router.push({path: '/page/' + item.url})
        }
      } else if (item.type === 'out_page') {
        this.$store.dispatch('setOutPageUrl', item.url)
        this.$router.push({name: 'business.out_page'})
      } else if (item.type === 'menu' && item.url && this.$route.name !== item.url) {
        this.$router.push({name: item.url})
      }
      if (item.roleId && item.roleId !== parseInt(localStorage.getItem('roleId'))) {
        localStorage.setItem('roleId', item.roleId)
        document.body.innerHTML = '<div style="position: fixed;width: 100vw;height: 100vh;background: #EEE;color: #657180;display: flex;align-items: center;justify-content: center;font-size: 20px;">角色切换中...</div>'
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }
  }
}
</script>
