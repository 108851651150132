<template>
  <div class="the-user" v-if="userInfo && userInfo.id" :class="{'is-pc': isPc}">
    <div class="the-user-detail" @click="show = !show && !isPc">
      <span class="user-name"><i class="iconfont icon-yonghu-"></i>{{userInfo.name}}/{{overviewRole ? '总览角色' : (currentRole ? currentRole.name : '无角色')}}</span>
    </div>
    <div class="menu-item-children" :style="{display: isPc ? null : (show ? 'flex' : 'none')}">
      <a href="javascript:;"
        key="user-name"
        style="font-weight: 800;border-bottom: 1px solid #E9EFF4;color: #000;cursor: default;"
        class="menu-item-children-item">
        <div class="menu-item-label">{{userInfo.name}}</div>
      </a>
      <a href="javascript:;"
        v-for="roleItem in availableRoles.filter(v => overviewRole || !currentRole || (v.id !== currentRole.id))"
        :key="roleItem.id"
        class="menu-item-children-item" @click="changRole(roleItem)">
        <div class="menu-item-label">{{roleItem.name}}</div>
      </a>
      <a href="javascript:;"
        v-if="availableRoles.length > 1"
        key="all-role"
        class="menu-item-children-item" @click="setOverviewRole">
        <div class="menu-item-label">总览角色</div>
      </a>
      <a href="javascript:;"
        style="border-top: 1px solid #E9EFF4"
        class="menu-item-children-item" @click="logOut">
        <div class="menu-item-label">注销</div>
      </a>
      <a href="javascript:;"
        class="menu-item-children-item" @click="showC">
        <div class="menu-item-label">修改密码</div>
      </a>
    </div>
    <fm-modal
      title="修改密码"
      :value="showChangePass"
      width="25rem"
      :mask-closable="false"
      theme="mh-withe"
      :closable="false"
      :cancel="handleClose">
      <div>
        <fm-input class="form-content_item" v-model="dataForm.password" type="password" placeholder="新密码" custom-icon="iconfont icon-mima-old"></fm-input>
        <fm-input style="margin-top: 2rem;" class="form-content_item" v-model="dataForm.rePassword" type="password" placeholder="重复密码" custom-icon="iconfont icon-mima-old"></fm-input>
      </div>
      <p style="margin: 30px 10px 0;">
        密码需要包含大写字符、小写字符、长度不小于8位!
      </p>
      <div slot="footer" style="display:flex;justify-content: flex-end;">
        <fm-btn
          @click="handleClose">
          取消
        </fm-btn>
        <fm-btn
          style="margin-left: 1rem;"
          @click="savePassword">
          确认修改
        </fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib'

import {
  updatePwd
} from '@/api'

import {
  testPassword
} from '@/syslib/user_lib'

export default {
  name: 'TheUser',
  data () {
    return {
      showChangePass: false,
      show: false,
      dataForm: {
        password: '',
        rePassword: ''
      }
    }
  },
  computed: {
    userInfo: {
      get () {
        return this.$store.getters.userInfo
      }
    },
    overviewRole () {
      return this.$store.getters.overviewRole
    },
    availableRoles: {
      get () {
        return this.$store.getters.availableRoles
      }
    },
    currentRole: {
      get () {
        return this.$store.getters.currentRole
      }
    },
    isPc () {
      return /^Mac|Win/.test(navigator.platform) || !navigator.platform
    }
  },
  methods: {
    setOverviewRole () {
      this.$store.dispatch('setOverviewRole', true)
    },
    changRole (role) {
      this.$store.dispatch('setOverviewRole', false)
      if (parseInt(localStorage.getItem('roleId')) !== parseInt(role.id)) {
        localstorageHelper.updateData('roleId', role.id)
        window.location.reload()
      }
    },
    savePassword () {
      if (!this.dataForm.password) {
        this.$notice.warning('系统提示', '密码不能为空!')
        return
      }
      if (this.dataForm.password !== this.dataForm.rePassword) {
        this.$notice.warning('系统提示', '两次密码输入不一致!')
        return
      }
      if (testPassword(this.dataForm.password) !== 'ok') {
        this.$notice.info({
          title: '系统提示',
          desc: '密码复杂度检测未通过，需要包含大写字符、小写字符、长度不小于8位!'
        })
        return
      }
      let parm = {
        password: this.dataForm.password
      }
      updatePwd(parm).then(() => {
        this.showChangePass = false
        this.$notice.success('系统提示', '密码修改成功!')
      }).catch(() => {
        this.showChangePass = false
      })
    },
    handleClose () {
      this.showChangePass = false
    },
    showC () {
      this.showChangePass = true
    },
    logOut () {
      this.$store.dispatch('logOut')
    }
  }
}
</script>

<style lang="less">
.the-user {
  .fm-modal-mask {
    z-index: 10000000 !important;
  }
  .fm-modal-wrap {
    z-index: 10000000 !important;
  }
}
</style>

<style scoped lang="less">
@import '../../styles/values.less';
@import '../../styles/theme.less';
.user-head-img {
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 1.8rem;
  }
}
.menu-item-children {
  max-height: 20rem;
  overflow-y: auto;
  position: absolute;
  display: none;
  left: 1rem;
  z-index: 100;
  flex-direction: column;
  top: @the-heading-height;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 4px 4px;
  background: #FFFFFF;
  // box-shadow: @size-shadow @color-shadow;
}
.the-user {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .iconfont, .icon-yifu {
    font-size: 1.5rem;
    font-weight: 800;
    margin-right: 5px;
  }
  .icon-yifu {
    cursor: pointer;
  }
}
.menu_div:hover {
  color: @color-main;
  .menu-item-children {
    display: flex;
  }
}
.the-user {
  font-size: 16px;
  color: #666;
}
.pink-theme {
  .the-user {
    color: #FFF;
  }
}
.blue-theme {
  .the-user {
    color: #FFF;
  }
}
.yellow-theme {
  .the-user {
    color: #6C573C;
  }
  .user-name {
    font-size: 16px;
  }
}
.menu-item-children {
  background: #f8f8f9;
}
.menu-item-children-item {
  color: #666;
  background: #FFF;
  padding: 0 20px;
}
.user-head-img {
  .iconfont {
    color: #666;
  }
}
.the-user-detail {
  display: flex;
}

.menu-item-label {
  white-space: nowrap;
}

.menu-item_div_active {
  border-color: @color-main;
  color: @color-main;
}
.menu-item-children-item {
  height: 3rem;
  min-height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.the-user.is-pc:hover {
  .menu-item-children {
    display: flex;
  }
}

.user-name {
  user-select: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 1.5rem 0 0.7rem;
  display: flex;
  align-items: center;
}
.white-theme {
  .the-user-detail {
    color: @color-norm-white;
  }
  .menu-item-children-item:hover {
    color: @color-bright-white;
  }
}
.dark-theme {
  .the-user-detail {
    color: @color-stress-dark;
  }
  .menu-item-children-item:hover {
    color: @color-bright-dark;
  }
}
</style>
