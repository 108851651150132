<style scoped lang="less">
  .left-menus {
    user-select: none;
    background-color: #FFF;
    overflow: auto;
    height: 100%;
    width: 182px;
    &::-webkit-scrollbar { width: 0 !important }
  }
  ul, li {padding: 0;margin: 0;}
  li {list-style: none;}

  .level-1 {
    .level-1-content {
      cursor: pointer;
      height: 80px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .level-1-name {
      transition: all .3s;
      font-size: 18px;
    }
    .level-1-icon {
      transition: all .3s;
      font-size: 30px;
    }
    &.has-child {
      .level-1-content::after {
        transition: all .3s;
        top: 20px;
        right: 17px;
        font-size: 20px;
        content: '...';
        color: #969AB8;
        position: absolute;
        writing-mode: vertical-lr;
      }
      .level-1-content::before {
        transition: all .3s;
        bottom: 0;
        left: 50%;
        font-family: 'iconfont';
        content: '\e656';
        transform-origin: center center;
        transform: translateX(-50%);
        color: #FFF;
        position: absolute;
        opacity: 0;
      }
    }
    &.active {
      .level-1-content::after {
        opacity: 0;
      }
      .level-1-content::before {
        opacity: 1;
      }
      .sub {
        display: flex;
      }
    }
  }

  .sub {
    display: none;
    flex-direction: column;
    box-sizing: border-box;
    .level-2 {
      position: relative;
      display: flex;
      cursor: pointer;
      justify-content: center;
    }
    .level-2.active {
      .level-2-name {
        &::before {
          opacity: 1;
        }
      }
    }
    .level-2-name {
      padding: 0 5%;
      box-sizing: border-box;
      display: block;
      text-align: left;
      color: #FFF;
      font-size: 16px;
      line-height: 40px;
      position: relative;
      &::before {
        font-family: 'iconfont';
        content: '\eca1';
        color: #FFF;
        opacity: 0;
        margin-right: 5px;
        transition: all .3s;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
</style>

<style lang="less">
  .pink-theme {
    .left-menus {
      .level-1 {
        .level-1-content:hover {
          .level-1-name, .level-1-icon {
            color: #F4628F;
          }
        }
        .level-1-name, .level-1-icon {
          color: #707070;
        }
        &.active {
          .level-1-content {
            background: #F4628F;
            &:hover {
              .level-1-name, .level-1-icon {
                color: #FFF;
              }    
            }
          }
          .level-1-name, .level-1-icon {
            color: #FFF;
          }
        }
      }
      .sub {
        background-color: #FBBAC4;
      }
    }
  }
  .blue-theme, .dark-theme {
    .left-menus {
      .level-1 {
        .level-1-content:hover {
          .level-1-name, .level-1-icon {
            color: #1728c7;
          }
        }
        .level-1-name, .level-1-icon {
          color: #6B75CA;
        }
        &.active {
          .level-1-content {
            background: #6B75CA;
          }
          .level-1-content:hover {
            .level-1-name, .level-1-icon {
              color: #FFF;
            }
          }
          .level-1-name, .level-1-icon {
            color: #FFF;
          }
        }
      }
      .sub {
        background-color: rgba(107, 117, 202, .6);
      }
    }
  }
  .yellow-theme {
    .left-menus {
      background-image: url('/static/images/yellow-left.png');
      background-size: 100% 100%;
      .sub .level-2-name, .level-1.has-child .level-1-content::after, .level-1-icon, .level-1-name {
        color: #6C573C;
      }
      .level-1.has-child.active {
        .level-1-content {
          background-color: #6C573C;
        }
        .level-1-icon, .level-1-name {
          color: #FFF;
        }
        .level-2.active {
          background-color: rgba(108, 87, 60, .6);
          .level-2-name {
            color: #FFF;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="left-menus">
    <ul class="main">
      <li class="level-1" :class="{
        'has-child': menu.children && menu.children.length,
        'active': level1 && level1.name === menu.name && (!level1.roleId || level1.roleId === menu.roleId)
      }" v-for="(menu, i) in menus" :key="menu.name + i">
        <div class="level-1-content" @click="!menu.children || !menu.children.length ? ($emit('choose-sub-menu'), clickMenu(menu)) : toggleSubMenu(menu, 1)" :data-name="menu.name">
          <i class="iconfont level-1-icon" :class="{[menu.icon || 'icon-app']: true}"></i>
          <span class="level-1-name">{{ menu.name }}</span>
        </div>
        <ul class="sub">
          <li class="level-2" @click="!subMenu.children || !subMenu.children.length ? ($emit('choose-sub-menu'), clickMenu(subMenu)) : openSubMenu(subMenu, 2)" :class="{'active': level2 && level2.name === subMenu.name}" v-for="(subMenu, l) in menu.children" :key="subMenu.name + l">
            <span class="level-2-name">{{subMenu.name}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import routes from '@/router/router_data'

function injectRoleId (menus, roleId) {
  for (let menu of menus) {
    if (menu.children && menu.children.length) {
      injectRoleId(menu.children, roleId)
    } else {
      menu.roleId = roleId
    }
  }
  return menus
}

function currentMenuPath (name, menus) {
  for (let item of menus) {
    if (item.children && item.children.length) {
      const res = currentMenuPath(name, item.children)
      if (res) {
        res.unshift(item)
        return res
      }
    } else if (item.url === name) {
      return [item]
    }
  }
}

export default {
  name: "LeftMenus",
  data() {
    return {
      level1: null,
      level2: null
    };
  },
  computed: {
    current () {
      return currentMenuPath(this.$store.state.route.name, this.menus)
    },
    menus () {
      let menus = JSON.parse(JSON.stringify(this.overviewRole ? this.overviewMenus : this.$store.getters.availableMenus))

      ;(function flat (menus, obj) {
        for (let menu of menus) {
          if (Array.isArray(menu.children)) {
            flat.bind(this)(menu.children, obj)
          }
          if (menu.url) {
            if (obj[menu.url] === undefined) {
              obj[menu.url] = 0
            } else {
              obj[menu.url]++
              let res = (function getRoute (routers, pname, url) {
                for (let route of routers) {
                  if (Array.isArray(route.children)) {
                    let r = getRoute(route.children, route.name, url)
                    if (r) {
                      return r
                    }
                  }
                  if (route.name === url) {
                    return { route, pname }
                  }
                }
              })(routes, null, menu.url)
              if (res) {
                this.$router.addRoute(res.pname, {
                  path: res.route.path + obj[menu.url],
                  name: res.route.name + obj[menu.url],
                  component: res.route.component
                })
                menu.url = menu.url + obj[menu.url]
              }
            }
          }
        }
      }).bind(this)(menus, {})

      return menus
    },
    overviewMenus () {
      const availableRoles = JSON.parse(JSON.stringify(this.availableRoles))
      let menus = []
      availableRoles.forEach((v) => {
        menus.push({
          name: v.name,
          icon: 'icon-jiantouyou'
        })
        menus = menus.concat(injectRoleId(v.menus, v.id))
      })
      return menus
      // return availableRoles.map(v => injectRoleId(v.menus, v.id)).flat()
    },
    overviewRole () {
      return this.$store.getters.overviewRole
    },
    availableRoles () {
      return this.$store.getters.availableRoles
    }
  },
  watch: {
    current: {
      deep: true,
      immediate: true,
      handler (current) {
        let [level1, level2] = current || []

        if (!level1 || !this.level1 || this.level1.id !== level1.id) {
          this.level1 = level1
        }
        if (!level2 || !this.level2 || this.level2.id !== level2.id) {
          this.level2 = level2
        }
      }
    }
  },
  methods: {
    openSubMenu (item, level) {
      this['level' + level] = item
      this.$emit('choose-sub-menu', item)
      this.clickMenu(item.children[0])
    },
    toggleSubMenu (item, level) {
      if (this['level' + level] === item) {
        this['level' + level] = null
      } else {
        this['level' + level] = item
      }
    },
    clickMenu (item) {
      if (item.type === 'sys_page') {
        if (!item.url || isNaN(item.url)) {
          if (item.url) {
            this.$route.name !== item.url && this.$router.push({name: item.url})
          } else {
            this.$router.push({name: 'index'})
          }
        } else {
          this.$router.push({path: '/page/' + item.url})
        }
      } else if (item.type === 'out_page') {
        this.$store.dispatch('setOutPageUrl', item.url)
        this.$router.push({name: 'business.out_page'})
      } else if (item.type === 'menu' && item.url && this.$route.name !== item.url) {
        this.$router.push({name: item.url})
      }
      if (item.roleId && item.roleId !== parseInt(localStorage.getItem('roleId'))) {
        localStorage.setItem('roleId', item.roleId)
        document.body.innerHTML = '<div style="position: fixed;width: 100vw;height: 100vh;background: #EEE;color: #657180;display: flex;align-items: center;justify-content: center;font-size: 20px;">角色切换中...</div>'
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }
  }
}
</script>